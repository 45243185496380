import { Observable, of } from 'rxjs';

import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AboDto } from './abo.dto';

@Injectable({ providedIn: 'root' })
export class AboControllerService {
    abo: AboDto | undefined = { pages: 4, images: 100, pageSites: 10 };

    getAbo(
        params?: any,
        context?: HttpContext
    ): Observable<AboDto | undefined> {
        return of(this.abo);
    }

    createAbo(
        params: any,
        context?: HttpContext
    ): Observable<AboDto | undefined> {
        this.abo = params.body;

        return of(this.abo);
    }
}
